<template>
	<div>
		<headerTop></headerTop>
		<headerMin></headerMin>
		<navlist :state="4"></navlist>
		<!-- 主体 -->
		<div class="promotion">
			<!-- 轮播图 -->
			<div class="banner" :style="{height: height + 'px'}">
				<el-carousel trigger="click" :height="height + 'px'">
					<el-carousel-item v-for="(v,i) in banner_List" :key="i">
						<img :src="v.image" alt />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="tab">
				<div class="center">
					<span :class="{active:active==1}" @click="getgive(1)">买赠专区</span>|<span :class="{active:active==2}" @click="getgive(2)">折扣专区</span>
				</div>
			</div>
			<!-- 商品列表 -->
			<div class="goodsList wrap bgw">
				<!-- 商品 -->
				<div @click="GoDetails(v.id,v.shop_id)" v-for="(v,i) in activity_List" :key="i" class="goodsDetails">
					<img :src="v.goods_img" alt />
					<p class="one">￥{{v.price}}</p>
					<p class="two">{{v.goods_name}}</p>
					<p class="one">
						评论
						<span>{{v.commentSum}}</span>
						条
						<span class="discount">{{v.desc}}</span>
					</p>
					<button>立即购买</button>
				</div>
				<!-- 分页 -->
	
			</div>
			<div class="paging">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="12" @current-change="changePage">
				</el-pagination>
			</div>
		</div>

		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
	</div>
</template>
<script>
	import navlist from "@/components/home_repect/navlist.vue"
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom";
	import {
		banner
	} from "@/request/index"; //轮播
	import {
		getActiveGoods
	} from "@/request/index"; //  活动
	export default {
		components: {
			headerTop,
			headerMin,
			publicBottom,
			navlist
		},
		data() {
			return {
				searchState: 0,
				banner_List: [], //轮播图
				activity_List: [], //活动 列表
				height: 400,
				active: 1,
				total: "",
				page: 1,
			};
		},
		created() {
			this.start();
		},
		mounted() {
			setTimeout(() => {
				let height = document.getElementsByClassName("banner_img")[0]
					.offsetHeight;
				this.height = height + "";
			}, 1600);
		},
		methods: {
			changePage(page) {
				this.page = page;
				if (this.active == 1) {
					this.$axios.post("/api/goods/gift_index", {
						token: this.token,
						page:this.page,
					}).then(res => {
						this.activity_List = res.data.data.data;
						this.total=res.data.data.total;
					})
				} else {
					this.$axios.post("/api/goods/discount_index", {
						token: this.token,
						page:this.page,
					}).then(res => {
						this.activity_List = res.data.data.data;
						this.total=res.data.data.total;
					})
				}
			},
			GoDetails(id,shopId) {
				this.$router.push({
					path: "goodsDetails?id=" + id+"&shopId="+shopId
				});
			},
			getgive(type) {
				this.active = type;
				if (this.active == 1) {
					this.$axios.post("/api/goods/gift_index", {
						token: this.token,
						page:this.page,
					}).then(res => {
						this.activity_List = res.data.data.data;
						this.total=res.data.data.total;
					})
				} else {
					this.$axios.post("/api/goods/discount_index", {
						token: this.token,
						page:this.page,
					}).then(res => {
						this.activity_List = res.data.data.data;
						this.total=res.data.data.total;
					})
				}
			},
			start() {
				let shopId = localStorage.getItem("lzyyShopId");
				//  轮播图
				banner({
					position_id: 2
				}).then(res => {
					this.banner_List = res.data;
				});
				this.getgive(1);
			}
		}
	};
</script>
<style lang="less" scoped>
	@import "../../assets/less/index";

	.tab {
		height: 50px;
		line-height: 50px;
		text-align: center;
		color: #a8a8a8;

		.center {
			span {
				cursor: pointer;
				display: inline-block;
				padding: 2px 5px;
				line-height: 30px;

			}

			.active {
				color: #3beec2;
				border-bottom: 1px solid #3beec2;
			}
		}
	}

	.discount {
		padding: 2px 3px;
		background-color: #FDB65A;
		color: #fff !important;
	}
	.paging{
		margin-left: 100px;
		height: 50px;
		margin-bottom: 30px;
		position: relative;
		/deep/.el-pagination{
			margin: 0 auto;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
</style>
